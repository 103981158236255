import React, { useState, useEffect } from 'react';

import { MenuOutlined } from '@ant-design/icons';
import { Avatar, Image, Layout, Menu, Dropdown, Button } from 'antd';
import { get } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';

// import { setThemeMode } from '../../redux/reducers/user';
import { useTheme } from '../../ThemeContext';
import { green, white } from '../../utils';
import {
  links1,
  thinksabioPageDropDownMenu,
  myUniverseDropDownMenu,
  myUniverseDropDownMenuForPaid,
  userActionDropDown,
} from './DashboardHeader.constant';
import Sidebar from './DashboardSidebar';
import styles from './style.module.scss';
const { Header } = Layout;

interface Props extends RouteComponentProps<any> {
  user: Object;
  signOut: Function;
  menuItemStyle: Object;
  menuLinkStyle: Object;
  scrollY: number;
  chatUrl: string;
}

const DashboardHeader: React.FC<Props> = ({
  match,
  history,
  user,
  signOut,
  menuItemStyle,
  menuLinkStyle,
  scrollY,
  chatUrl,
}) => {
  // const dispatch = useDispatch();
  const { image, imageTime, personalData } = useSelector(
    ({ user, portfolio }) => ({
      image: get(user, 'personalData.additionalInfo.image_url', ''),
      imageTime: get(user, 'personalData.date', ''),
      telegramId: get(user, 'personalData.additionalInfo.telegram_id', ''),
      personalData: get(user, 'personalData', {}),
    }),
    shallowEqual,
  );
  const { theme, setTheme } = useTheme();
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [profilePic, setProfilePic] = useState({
    image: image,
    time: imageTime,
  });
  const name = get(user, 'given_name') ? get(user, 'given_name') : 'user';
  const lastName = get(user, 'family_name') ? get(user, 'family_name') : 'name';
  const userType = personalData['type'];
  const isStudent = userType === 'student';
  const selectedKeys = links1(isStudent && userType !== 'paid', userType)
    .filter(({ text }) => RegExp(text).test(get(match, 'path')))
    .map(({ text }) => text);
  if (selectedKeys.length > 1) selectedKeys.shift();

  const path = get(match, 'path');

  useEffect(() => {
    setProfilePic({
      image: image,
      time: imageTime,
    });
  }, [image, imageTime]);

  const onThemeChange = (darkMode) => {
    setTheme(darkMode ? 'dark' : 'light');
    // dispatch(setThemeMode(darkMode ? 'dark' : 'light'));
    localStorage.setItem('theme', darkMode ? 'dark' : 'light');
  };
  const navigateToDonate = () => {
    history.push('/donate');
  };
  return (
    <Header className={styles.VheaderBarNew}>
      <div className={styles.VheaderBarNewContent}>
        <div className={styles.headerLogoBox}>
          {isStudent ? (
            <NavLink to="/portfolio/Portfolio/virtual" className={styles.logo}>
              <img
                src={`https://thinksabio-asset.s3.amazonaws.com/TSLOGO-${theme}.png`}
                alt="logo"
              />
            </NavLink>
          ) : (
            <NavLink to="/portfolio/Portfolio/real" className={styles.logo}>
              <img
                src={`https://thinksabio-asset.s3.amazonaws.com/TSLOGO-${theme}.png`}
                alt="logo"
              />
            </NavLink>
          )}
        </div>
        <div className={styles.menuContainer}>
          <div id="myUniverse" className={styles.dashboardMenu}>
            <Dropdown
              overlay={
                userType === 'paid'
                  ? myUniverseDropDownMenuForPaid
                  : myUniverseDropDownMenu
              }
              placement="bottomCenter"
              arrow
              onClick={() =>
                history.push(
                  isStudent
                    ? '/portfolio/Portfolio/virtual'
                    : '/portfolio/Portfolio/real',
                )
              }
              // visible={true}
            >
              <p
                style={{
                  ...menuLinkStyle,
                  borderBottom:
                    (path === '/trending/:type/:subType' ||
                      path ===
                        '/portfolio/:mainTab/:type/:portfolioQueryData?' ||
                      path === '/alerts' ||
                      path === '/stock/:symbol/:type') &&
                    `2px solid ${green}`,
                  color:
                    path === '/trending/:type/:subType' ||
                    path === '/alerts' ||
                    path === '/portfolio/:mainTab/:type/:portfolioQueryData?' ||
                    path === '/stock/:symbol/:type'
                      ? green
                      : white,
                  margin: 0,
                  cursor: 'pointer',
                  fontWeight: 700,
                  // marginBottom: 10,
                }}
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                MyUniverse
              </p>
            </Dropdown>
          </div>
          <Menu
            theme="light"
            mode="horizontal"
            style={{
              float: 'right',
              border: 'none',
              height: '100%',
              paddingLeft: 5,
            }}
            defaultSelectedKeys={['0']}
            selectedKeys={selectedKeys}
            className={styles.dashboardMenuNew}
          >
            {links1(isStudent && userType !== 'paid').map(
              ({ name, path, text, id, isNew }) => (
                <Menu.Item
                  key={text}
                  style={menuItemStyle}
                  className={styles.menuItem}
                >
                  {name === 'Earnings Calender' ? (
                    <NavLink to={path} activeClassName={styles.navActiveClass}>
                      <div
                        style={{
                          ...menuLinkStyle,
                          backgroundColor: `${green} !important`,
                          borderBottom:
                            selectedKeys.includes(text) && `2px solid ${green}`,
                        }}
                        id={id}
                        className={styles.communityLink}
                      >
                        {name}
                        {<span>New</span>}
                      </div>
                    </NavLink>
                  ) : (
                    <NavLink to={path} activeClassName={styles.navActiveClass}>
                      <div
                        style={{
                          ...menuLinkStyle,
                          backgroundColor: `${green} !important`,
                          borderBottom:
                            selectedKeys.includes(text) && `2px solid ${green}`,
                        }}
                        id={id}
                        className={styles.communityLink}
                      >
                        {name}
                        {isNew && <span>New</span>}
                      </div>
                    </NavLink>
                  )}
                </Menu.Item>
              ),
            )}
          </Menu>

          <div id="thinksabio" className={styles.dashboardMenu}>
            <Dropdown
              overlay={thinksabioPageDropDownMenu(signOut)}
              placement="bottomCenter"
              arrow
              // onClick={() => history.push('/trending/symbol/daily')}
            >
              <p
                style={{
                  ...menuLinkStyle,
                  borderBottom:
                    (path === '/founder-story' ||
                      path === '/university' ||
                      // path === '/earningsCalender' ||
                      path === '/faq' ||
                      path === '/contact-us') &&
                    `2px solid ${green}`,
                  color:
                    path === '/founder-story' ||
                    path === '/university' ||
                    path === '/faq' ||
                    path === '/contact-us'
                      ? green
                      : white,
                  margin: 0,
                  fontWeight: 700,
                  // marginBottom: 10,
                  cursor: 'pointer',
                }}
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                ThinkSabio
              </p>
            </Dropdown>
          </div>
          <div id="tools" className={styles.dashboardMenu}>
            <p
              style={{
                ...menuLinkStyle,
                borderBottom: path === '/tools' ? `2px solid ${green}` : '',
                color: white,
                margin: 0,
                fontWeight: 700,
                // marginBottom: 10,
                cursor: 'pointer',
              }}
              className="ant-dropdown-link"
              onClick={() => history.push('/Tools')}
            >
              Tools
            </p>
          </div>
          {isStudent ? (
            ''
          ) : (
            <div id="donate" className={styles.dashboardMenu}>
              <p
                style={{
                  ...menuLinkStyle,
                  borderBottom: path === '/donate' ? `2px solid ${green}` : '',
                  color: white,
                  margin: 0,
                  fontWeight: 700,
                  // marginBottom: 10,
                  cursor: 'pointer',
                }}
                className="ant-dropdown-link"
                onClick={navigateToDonate}
              >
                Donate
              </p>
            </div>
          )}
          <div className={styles.dashboardMenu}>
            {profilePic.image ? (
              <div className={styles.profilePic}>
                <Image key={profilePic.time} src={profilePic.image} />
              </div>
            ) : (
              <Avatar size={32} style={{ paddingLeft: 15 }}>
                {`${name[0]}${lastName ? lastName[0] : ''}`}
              </Avatar>
            )}
          </div>
          <div id="vAvtorNew" className={styles.dashboardProfileIcon}>
            <Dropdown
              overlay={userActionDropDown(signOut, onThemeChange, theme, true)}
              placement="bottomCenter"
              arrow
              // trigger={['click']}
            >
              <p
                style={{
                  ...menuLinkStyle,
                  //   borderBottom:
                  //     (path === '/personal-information' ||
                  //       path === '/change-password' ||
                  //       path === '/financial-information') &&
                  //     `2px solid ${green}`,
                  //   color:
                  //     path === '/personal-information' ||
                  //     path === '/change-password' ||
                  //     path === '/financial-information'
                  //       ? green
                  //       : white,
                  margin: 0,
                  cursor: 'pointer',
                  fontWeight: 700,
                  // marginBottom: 10,
                }}
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <span style={{ paddingLeft: 10, textTransform: 'capitalize' }}>
                  {name}
                </span>
              </p>
            </Dropdown>
          </div>
          <Button
            className={styles.mobileMenuBtn}
            type="primary"
            style={{
              float: 'right',
              height: 30,
              width: 30,
              padding: 1,
              borderRadius: 5,
            }}
            onClick={() => setShowSidebar(true)}
          >
            <MenuOutlined />
          </Button>
        </div>
      </div>

      <Sidebar
        showSidebar={showSidebar}
        setShowSidebar={(value) => setShowSidebar(value)}
        menuLinkStyle={menuLinkStyle}
        match={match}
        signOut={signOut}
        name={name}
        chatUrl={chatUrl}
        theme={theme}
        onThemeChange={onThemeChange}
        profilePic={profilePic}
        lastName={lastName}
        userType={userType}
        isStudent={isStudent}
      />
    </Header>
  );
};

export default DashboardHeader;
