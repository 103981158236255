import React, { useState, useContext } from 'react';

import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Table,
  Row,
  Empty,
  Modal,
  Collapse,
  Typography,
  Button,
  List,
  notification,
} from 'antd';
import { get } from 'lodash';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';

import {
  addSymbolToTsWatchlist,
  getIIRQuarters,
  getTSWatchlistData,
} from '../../graphql';
import { setIIRQuarters } from '../../redux/reducers/report';
import { useTheme } from '../../ThemeContext';
import {
  green,
  formatTotalRow,
  formatTableData,
  currencyFormat,
  errorHandler,
} from '../../utils';
import SaveReport from './SaveReport';
import { getSearchSymbolTablesData } from './SearchReport.helper';
import { SearchReportContext } from './SearchReportContext';
import styles from './style.module.scss';

const { Panel } = Collapse;
const { Paragraph } = Typography;

const SearchSymbolPage = () => {
  const {
    searchData = {},
    isLoading,
    reportInfo,
    currentSearchCriteria,
    reportType,
    reportSubType,
    reportTitle,
    fetchReportName,
    fetchingName,
    history,
    handleISRTOIIR,
    setIsLoading,
    setFilterOptions,
    handleISRTOStatusReport,
  } = useContext(SearchReportContext);
  const getCurrentQtrString = () => {
    const date = new Date();
    return (
      'Q' + (Math.floor(date.getMonth() / 3) + 1) + '-' + moment().format('YY')
    );
  };

  const { theme } = useTheme();
  const dispatch = useDispatch();
  const [openKeys, setOpenKeys] = useState(['1']);
  const [sortDetail, setSortDetail] = useState([]);
  const { company = '', companyProfile = '' } = searchData;
  const { symbol } = reportInfo;

  const ISRTOIIIRHandler = (iirData) =>
    Modal.confirm({
      title: 'Open Individual Institution Report',
      content: (
        <p>{`Are you sure you want to open ${iirData.institutionsName[0]} Report ?`}</p>
      ),
      onOk: () => handleTelegramUnlink(iirData),
    });
  const statusParamsMap = {
    INCREASED: 'INCREASED',
    DECREASED: 'REDUCED',
    SOLD_OFF: 'SOLD OFF',
    NEWLY_BOUGHT: 'NEWLY BOUGHT',
    NO_CHANGE: 'NO CHANGE',
  };
  const ISRTOStatusReport = (statusData) =>
    Modal.confirm({
      title: 'Open Status Report',
      content: (
        <p>{`Are you sure you want to open ${
          statusParamsMap[statusData.multiStatus[0]]
        } Report ?`}</p>
      ),
      onOk: () => handleStatusReportRedirect(statusData),
    });

  const handleStatusReportRedirect = (statusData) => {
    setFilterOptions({
      multiStatus: statusData.multiStatus,
      symbols: statusData.symbols,
    });
    handleISRTOStatusReport();
    setTimeout(() => {
      history.push(`/search/report/` + queryString.stringify(statusData));
    }, 1000);
  };
  const handleTelegramUnlink = (iirData) =>
    getIIRQuarters({
      context: iirData.subtype.toUpperCase(),
      ciks: iirData.institutions,
    }).then((res) => {
      setIsLoading(true);
      dispatch(setIIRQuarters(iirData.institutions[0], res));
      // setQuartersOptions(res);
      // setSelectedQuarters(res[0].value);
      setFilterOptions({});
      handleISRTOIIR();
      setTimeout(() => {
        history.push(`/search/report/` + queryString.stringify(iirData));
      }, 1000);
    });

  let tablesData = getSearchSymbolTablesData(
    searchData,
    symbol,
    sortDetail,
    theme,
    history,
    ISRTOIIIRHandler,
    ISRTOStatusReport,
  );
  const onPanelChange = (keys) => {
    setOpenKeys(keys);
  };
  const openAllPanel = () => {
    let keys = new Array(tablesData.length)
      .fill(0)
      .map((_, i) => (i + 1).toString());

    setOpenKeys(keys);
  };
  const tableLoading = {
    spinning: isLoading,
    indicator: (
      <span className={styles.spinnerStyle}>
        <BounceLoader color={green} loading={true} size={50} />
      </span>
    ),
  };

  const onTableChange = (pagination, filters, sorter, extra, key) => {
    const { field, order } = sorter;
    setSortDetail([
      {
        key: key,
        sortBy: field,
        sortOrder: order === 'ascend' ? 'ASC' : 'DESC',
      },
    ]);
  };
  const addToTsWatchlist = async () => {
    console.log(symbol);
    getTSWatchlistData({
      noOfQtrs: 2,
    })
      .then(async (res) => {
        const symbolArray = (res.data || []).map((item) => item.symbol);
        if (symbolArray.includes(symbol)) {
          await notification.error({
            message: `${symbol} already exists in your Watch List`,
          });
        } else {
          const payload = {
            symbol: symbol,
            type: 'add',
          };
          const addSymbolResponse = await addSymbolToTsWatchlist(payload);
          if (addSymbolResponse.symbolAdded) {
            await notification.success({
              message: 'Symbol Added Successfully',
            });
          } else {
            notification.error({
              message: 'Failed to Add Symbol',
            });
          }
        }
      })
      .catch(errorHandler)
      .finally(() => {});
  };
  const getSortedData = (key, data) => {
    const sortObj = sortDetail.find((d) => d.key === key);
    if (sortObj) {
      const newData = data.sort((a, b) => {
        let keyA =
          sortObj.sortBy === 'dateReported' ||
          sortObj.sortBy === 'transactionDate' ||
          sortObj.sortBy === 'reportDate'
            ? new Date(a[sortObj.sortBy])
            : a[sortObj.sortBy];
        let keyB =
          sortObj.sortBy === 'dateReported' ||
          sortObj.sortBy === 'transactionDate' ||
          sortObj.sortBy === 'reportDate'
            ? new Date(b[sortObj.sortBy])
            : b[sortObj.sortBy];
        // Compare the 2 dates
        if (sortObj.sortOrder === 'ASC') {
          if (!isNaN(+keyA)) {
            return keyA - keyB;
          } else {
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          }
        } else {
          if (!isNaN(+keyA)) {
            return keyB - keyA;
          } else {
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
          }
        }
      });

      // const newData = orderBy(
      //   data,
      //   [sortObj.sortBy],
      //   [sortObj.sortOrder.toLowerCase()],
      // );
      console.log(newData);
      return newData;
    }
    return data;
  };

  return (
    <div className={styles.isrReport}>
      <div className={styles.vGoBackSaveBar}>
        <p
          onClick={() =>
            get(history, 'location.state.prevPage', '') === 'search'
              ? history.push({
                  pathname: '/search',
                  state: { type: reportType, subType: reportSubType },
                })
              : history.goBack()
          }
          className={styles.vGoBackBtnBlock}
        >
          <span className={styles.vGoBackBtn}>
            <ArrowLeftOutlined /> Go Back
          </span>
        </p>
        {!isLoading && (
          <>
            <div
              className={styles.SaveReportBoxNew2}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <div>
                <p
                  className="ant-dropdown-link"
                  style={{ width: '180px', marginRight: '20px' }}
                  onClick={() => {
                    addToTsWatchlist();
                  }}
                >
                  <PlusOutlined /> Add To Watchlist
                </p>
              </div>
              <SaveReport
                searchCriteria={currentSearchCriteria}
                reportType={reportType}
                reportSubType={reportSubType}
                reportTitle={reportTitle}
                fetchReportName={fetchReportName}
                fetchingName={fetchingName}
              />
            </div>
          </>
        )}
      </div>
      <div>
        <div className={styles.vSavedBlockedNew}>
          {!isLoading && (
            <>
              <p style={{ fontSize: 20, textTransform: 'uppercase' }}>
                {' '}
                {` Individual Statistical Report for (${symbol}) ${
                  company ? company : ''
                }`}
              </p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://finance.yahoo.com/quote/${symbol}`}
              >
                (Yahoo Finance)
              </a>
            </>
          )}

          <div className={styles.titleContainer}>
            {/* <h2 style={{ marinTop: 0 }}>{title}</h2> */}
          </div>
        </div>
        {!isLoading && (
          <Paragraph ellipsis={{ rows: 2, expandable: true }}>
            <text
              style={{ cursor: 'text', userSelect: 'text' }}
              onCopy={() => navigator.clipboard.writeText(companyProfile)}
            >
              {companyProfile}
            </text>
          </Paragraph>
        )}

        {!isLoading && (
          <Row justify="end" span={24} style={{ marginBottom: 10 }}>
            {openKeys.length !== tablesData.length ? (
              <Button onClick={openAllPanel}>Open All</Button>
            ) : (
              <Button onClick={() => setOpenKeys([])}>Close All</Button>
            )}
          </Row>
        )}
      </div>
      <Row span={24} id="isr">
        {isLoading && (
          <div className={styles.loaderStyle}>
            <BounceLoader color={green} loading={isLoading} size={150} />
          </div>
        )}

        {tablesData.length > 0 && (
          <Collapse
            defaultActiveKey={['1']}
            expandIconPosition="left"
            style={{ width: '100%' }}
            activeKey={openKeys}
            onChange={onPanelChange}
          >
            {tablesData.map(
              (field, i) =>
                field.data.length > 0 && (
                  <Panel
                    style={{ color: 'red' }}
                    header={field.title.toUpperCase()}
                    key={i + 1}
                    id="vPanelChange"
                    // extra={
                    //   i === 0 ? (
                    //     <Button onClick={openAllPanel}>Open All</Button>
                    //   ) : (
                    //     ''
                    //   )
                    // }
                  >
                    {field.type === 'table' && (
                      <div className={styles.searchPaneTable}>
                        <Table
                          scroll={{ x: true }}
                          loading={tableLoading}
                          columns={field.columns}
                          dataSource={formatTableData(
                            getSortedData(field.name, field.data),
                          )}
                          showSorterTooltip={false}
                          pagination={false}
                          size="small"
                          bordered={true}
                          rowClassName={styles.tableHeader}
                          className={styles.TabularData}
                          onChange={(a, b, c, d) =>
                            onTableChange(a, b, c, d, field.name)
                          }
                          id="allPicksId"
                          summary={
                            !field.summary
                              ? () => {}
                              : () => {
                                  return (
                                    <Table.Summary.Row>
                                      {formatTotalRow(field.summary[0]).map(
                                        (r, i) => (
                                          <Table.Summary.Cell index={i} key={i}>
                                            {r}
                                          </Table.Summary.Cell>
                                        ),
                                      )}
                                    </Table.Summary.Row>
                                  );
                                }
                          }
                        />
                        {field.name === 'ownersInfo' && (
                          <p
                            style={{
                              fontSize: 12,
                            }}
                          >
                            *Data Source{' '}
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={field.dataSource}
                              style={{
                                textDecoration: 'underline',
                                fontSize: 12,
                                color: green,
                              }}
                            >
                              SEC
                            </a>
                          </p>
                        )}
                        {field.name === 'financialData' && (
                          <p
                            style={{
                              fontSize: 12,
                              textAlign: 'center',
                            }}
                          >
                            {' '}
                            {`Note: Date displayed  in ${getCurrentQtrString()} column is Expected Earnings Date`}
                          </p>
                        )}
                        {field.name === 'mutualFundQuarterlyShares' && (
                          <p
                            style={{
                              textDecoration: 'underline',
                              fontSize: 12,
                              color: green,
                              marginTop: 10,
                              cursor: 'pointer',
                              textAlign: 'center',
                            }}
                            onClick={() => {
                              const filters = {
                                subtype:
                                  'SYMBOL_SUMMARY_MUTUAL_FUNDS'.toLowerCase(),
                                type: 'MUTUAL_FUNDS'.toLowerCase(),
                                symbols: symbol,
                              };
                              if (history) {
                                history.push(
                                  `/search/report/` +
                                    queryString.stringify(filters),
                                );
                              }
                            }}
                          >
                            View All
                          </p>
                        )}
                        {field.name === 'insiderTransactions' && (
                          <p
                            style={{
                              textDecoration: 'underline',
                              fontSize: 12,
                              color: green,
                              marginTop: 10,
                              cursor: 'pointer',
                              textAlign: 'center',
                            }}
                            onClick={() => {
                              const filters = {
                                type: 'SEC_INSIDERS'.toLowerCase(),
                                subtype:
                                  'INSIDER_DETAIL_SUMMARY_REPORT'.toLowerCase(),
                                symbols: symbol,
                              };
                              if (history) {
                                history.push(
                                  `/search/report/` +
                                    queryString.stringify(filters),
                                );
                              }
                            }}
                          >
                            View All
                          </p>
                        )}
                      </div>
                    )}
                    {field.type === 'table' &&
                      field.title.includes('RECENT TRANSACTIONS') && (
                        <p
                          style={{
                            color: 'var(--whitecolor)',
                            marginLeft: '710px',
                            marginTop: '5px',
                          }}
                        >
                          {' '}
                          Total :{' '}
                          {currencyFormat(
                            field.total || 0,
                            false,
                            false,
                            2,
                            false,
                            true,
                          )}
                        </p>
                      )}
                    {/*{field.type === 'table' &&*/}
                    {/*  field.title.includes('Comparison by Quarterly Price') && (*/}
                    {/*    <p*/}
                    {/*      style={{*/}
                    {/*        color: 'var(--whitecolor)',*/}
                    {/*        marginLeft: '100px',*/}
                    {/*        marginTop: '5px',*/}
                    {/*        fontSize: '10px',*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      Formula For Approx Invested Value : (Shares Diff * Qtr*/}
                    {/*      Avg Price)*/}
                    {/*    </p>*/}
                    {/*  )}*/}

                    {field.type === 'news' && (
                      <List
                        size="small"
                        // header={}
                        bordered
                        dataSource={field.data}
                        renderItem={(item: {}) => (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                <>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={item.url}
                                  >
                                    <p className={styles.newsTitle}>
                                      {item.title}
                                    </p>
                                  </a>

                                  <p className={styles.news_time}>
                                    {`${item.source} - ${moment(item.timestamp)
                                      .tz('America/New_York')
                                      .format('hh:mm A')},

                                ${moment(item.timestamp)
                                  .tz('America/New_York')
                                  .format('DD MMM YYYY')}`}
                                  </p>
                                </>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    )}
                  </Panel>
                ),
            )}
          </Collapse>
        )}
      </Row>
      <Row span={24} justify="center">
        {tablesData.length === 0 && !isLoading && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Row>
    </div>
  );
};

export default SearchSymbolPage;
