import React, { useEffect, useState } from 'react';

import { ArrowLeftOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Input } from 'antd';
import { get, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';

import { Pagination, Table } from '../../components';
import { getEarningsData } from '../../graphql';
import { useTheme } from '../../ThemeContext';
import {
  errorHandler,
  formatColumn,
  formatNumber2,
  formatTableData,
} from '../../utils';
import FilterForm from '../reports/FilterForm';
import FilteredValues from '../trendingData/FilteredValues';
import styles from './style.module.scss';

const EarningsCalender: React.FC<Props> = ({ history }) => {
  const { RangePicker } = DatePicker;
  const [date, setDate] = useState([
    moment().tz('America/New_York'),
    moment().tz('America/New_York').add(1, 'months'),
  ]);
  // const { Option } = Select;
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const [tableColumns, setTableColumns] = useState();
  const [openFilterForm, setOpenFilterForm] = useState<boolean>(false);
  const [tableData, setTableData] = useState(null);
  const { theme } = useTheme();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBM, setIsBM] = useState<boolean>(true);
  const [isDM, setIsDM] = useState<boolean>(true);
  const [isAM, setIsAM] = useState<boolean>(true);
  const [searchedText, setSearchedText] = useState('');
  // const [dropdown, setDropdown] = useState('bmo');
  const [sortDetail, setSortDetail] = useState([
    {
      sortBy: 'eps_perc',
      sortOrder: 'DESC',
    },
  ]);
  const total = tableData && tableData.data ? get(tableData, 'total') : null;

  useEffect(() => {
    setIsLoading(true);
    let category = [];
    if (isBM && isDM && isAM) {
      category.push('');
    }
    if (isBM) {
      category.push('bmo');
    }
    if (isAM) {
      category.push('amc');
    }
    if (isDM) {
      category.push('dmh');
    }
    const apis = [
      getEarningsData({
        fromDate: date[0].format('YYYY-MM-DD'),
        toDate: date[1].format('YYYY-MM-DD'),
        sortBy: sortDetail[0].sortBy,
        sortOrder: sortDetail[0].sortOrder,
        searchText: searchedText,
        page: pageNo,
        size: pageSize,
        filters: { ...filters, category: category },
      }),
    ];

    Promise.all(apis)
      .then((res) => {
        dispatch(setTableData(res[0]));
        dispatch(setTableColumns(arrangeColumns(get(res[0], 'columns'))));
      })
      .catch(errorHandler)
      .finally(() => {
        console.log('tableColumns', tableColumns);
        setIsLoading(false);
      });
  }, [
    dispatch,
    tableColumns,
    date,
    pageSize,
    pageNo,
    sortDetail,
    searchedText,
    filters,
    isBM,
    isAM,
    isDM,
  ]);
  const arrangeColumns = (columns = {}) => {
    const formattedColumns = Object.keys(columns).map((col, index) => ({
      key: col,
      name: col,
      title: col === 'delete' ? 'Delete' : null,
      value: columns[col],
      visible: true,
      index: col === 'symbol' || col === 'symbols' ? 0 : index + 1,
    }));
    return formattedColumns;
  };
  const handleFilterValueClick = (name, value) => {
    const updatedFilters = { ...filters };
    delete updatedFilters[name];
    setFilters(updatedFilters);
    setOpenFilterForm(false);
  };
  const handleReset = () => {
    setFilters({});
  };

  const handleSort = (sortBy: string, sortOrder: string) => {
    setSortDetail([
      {
        sortBy: sortBy,
        sortOrder: sortOrder,
      },
    ]);
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    if (order === 'ascend') {
      handleSort(field, 'ASC');
    } else {
      handleSort(field, 'DESC');
    }
  };
  const onPaginationChange = async (page: number, pageSize: number) => {
    setPageSize(pageSize);
    setPageNo(page);
  };
  const onChange = (date) => {
    setDate(date);
  };
  // const selectObjectList = (options: Array<{ name: string; value: string }>) =>
  //   options.map((p) => (
  //     <Option key={p.value} value={p.value}>
  //       {p.name}
  //     </Option>
  //   ));

  const onSearchChange = (event) => {
    const { value } = event.target;
    setSearchedText(value);
  };
  const onFilter = (val, sort) => {
    setFilters(val);
    if (sort && Array.isArray(sort)) {
      setSortDetail(sort);
    }
  };
  // const onDropdownChange = (value) => {
  //   setDropdown(value);
  // };

  function onBMChange() {
    setIsBM(!isBM);
  }
  function onDMChange() {
    setIsDM(!isDM);
  }
  function onAMChange() {
    setIsAM(!isAM);
  }

  return (
    <>
      <div className={styles.joinTelegramSection}>
        <div style={{ marginLeft: 50 }} className={styles.vGoBackSaveBar}>
          <p
            onClick={() => history.push('/search')}
            className={styles.vGoBackBtnBlock}
          >
            <span className={styles.vGoBackBtn}>
              <ArrowLeftOutlined /> Go Back
            </span>
          </p>
          <h3
            style={{
              color: '#2fc058',
              textAlign: 'center',
              marginBottom: '20px',
              paddingRight: '100px',
              margin: 'auto',
            }}
          >
            Stock Earnings Report (SER)
          </h3>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: 'auto',
            marginBottom: '20px',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ width: '150px', marginLeft: 225 }}>
              <Input
                placeholder="search symbol..."
                onChange={onSearchChange}
                className={styles.VdailySearch}
                allowClear
              />
            </div>
            <div
              style={{
                width: 350,
                marginLeft: 225,
                marginTop: 20,
                marginRight: '50px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div>
                <RangePicker
                  style={{ width: '80%' }}
                  onChange={onChange}
                  value={date}
                  allowClear={false}
                  format="DD MMM YYYY"
                />
              </div>
            </div>
          </div>
          <div
            className={styles.reportOptionBlock}
            style={{ marginRight: 20, width: 600, marginTop: '55px' }}
            id="reportQuarters"
          >
            <Checkbox checked={isBM} onChange={onBMChange}>
              {' '}
              Before Market
            </Checkbox>
            <Checkbox checked={isDM} onChange={onDMChange}>
              During Market
            </Checkbox>
            <Checkbox checked={isAM} onChange={onAMChange}>
              After Market
            </Checkbox>
            {/*Announce:*/}
            {/*<Select*/}
            {/*  defaultValue={'bmo'}*/}
            {/*  style={{ width: 200 }}*/}
            {/*  onChange={(value) => onDropdownChange(value)}*/}
            {/*  placeholder="Select Type"*/}
            {/*  disabled={isLoading}*/}
            {/*>*/}
            {/*  {selectObjectList([*/}
            {/*    { name: 'ALL', value: '' },*/}
            {/*    { name: 'Before Market Open', value: 'bmo' },*/}
            {/*    { name: 'After Market Close', value: 'amc' },*/}
            {/*    { name: 'During Market Hours', value: 'dmh' },*/}
            {/*  ])}*/}
            {/*</Select>*/}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '50px',
              marginBottom: '20px',
              marginRight: '225px',
            }}
          >
            <div className={styles.filterValueBox}>
              {!isEmpty(filters) &&
                Object.values(filters).filter((d) => d).length > 0 && (
                  <FilteredValues
                    filterOptions={filters}
                    handleClick={handleFilterValueClick}
                    handleReset={handleReset}
                  />
                )}
            </div>
            <Button
              icon={<FilterOutlined />}
              size={20}
              onClick={() => {
                setOpenFilterForm(!openFilterForm);
              }}
              //style={{ marginRight: 20 }}
              className={styles.Vfilterbtn}
              disabled={false}
              id="reportColumnFilters"
            >
              Filter/Sort
            </Button>
          </div>
        </div>
        <div>
          {openFilterForm && (
            <FilterForm
              visible={openFilterForm}
              onCancel={() => setOpenFilterForm(false)}
              onFilter={onFilter}
              filterOptions={filters}
              reportCode={'earningsCalender'}
              columns={formatColumn(
                (arrangeColumns(get(tableData, 'columns')) || []).filter(
                  (col) => col.visible !== false,
                ),
                true,
                sortDetail,
                history,
                false,
                false,
                'watchList',
                null,
                null,
                theme,
              )}
              sortDetail={[
                {
                  sortBy: sortDetail[0]?.sortBy ? sortDetail[0]?.sortBy : '',
                  sortOrder: sortDetail[0]?.sortOrder
                    ? sortDetail[0]?.sortOrder
                    : '',
                },
              ]}
              // handleSortReset={() => setSortDetail([])}
              // handleSortSubmit={(val) => setSortDetail(val)}
            />
          )}
          <Table
            style={{ width: '70%', margin: 'auto' }}
            // scroll={{ y: 500 }}
            columns={formatColumn(
              (arrangeColumns(get(tableData, 'columns')) || []).filter(
                (col) => col.visible !== false,
              ),
              true,
              sortDetail,
              history,
              false,
              false,
              'watchList',
              null,
              null,
              theme,
            )}
            dataSource={formatTableData(get(tableData, 'data', []) || [])}
            loading={isLoading}
            pagination={false}
            size="small"
            bordered={true}
            rowClassName={styles.tableHeader}
            className={styles.TabularData}
            onChange={onTableChange}
            id="allPicksId"
          />
          {total > 20 ? (
            <div style={{ width: '800px', margin: 'auto' }}>
              <Pagination
                pageSize={pageSize}
                total={total}
                pageNo={pageNo}
                onChange={onPaginationChange}
                loading={isLoading}
                showPagination={true}
                hideDisclaimer={true}
              />
            </div>
          ) : (
            total > 0 && (
              <p style={{ marginLeft: '280px', color: 'var(--whitecolor)' }}>
                {`Showing 1 to ${total} of ${formatNumber2(total)} entries`}{' '}
              </p>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default EarningsCalender;
