import React, { useEffect } from 'react';

import { Layout as AntLayout } from 'antd';
import { get } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';

import { Footer, Header } from '../components';
import { setLocationTitle } from '../utils/LocationHelper';
import styles from './style.module.scss';

interface Props extends RouteComponentProps<any> {
  component: React.ReactNode;
  authState?: string;
  location: Object;
  path: string;
  showHeader: boolean;
  showFooter: boolean;
  title: string;
}

const AuthLayout: React.FC<Props> = ({
  component: Component,
  authState,
  history,
  location,
  path,

  showHeader = true,
  showFooter = true,
  ...props
}) => {
  const { stripeCustomer } = useSelector(
    ({ user }) => ({
      stripeCustomer: get(user, 'profile.stripeCustomer'),
    }),
    shallowEqual,
  );
  const stripeCustomerId = get(stripeCustomer, 'stripe_customer_id');

  useEffect(() => {
    setLocationTitle(location);
  }, [location]);

  //   const store = useStore();
  //   const redirectPath = () => {
  //     let path = '/dashboard';
  //     let subscriptionStatus = get(
  //       store.getState(),
  //       'user.profile.stripeCustomer.subscription_status',
  //       'N/A',
  //     );
  //     if (subscriptionStatus === 'ACTIVE' || subscriptionStatus === 'TRIAL') {
  //       path = '/dashboard';
  //     }
  //     return path;
  //   };
  return (
    <Route
      {...props}
      render={(matchProps) =>
        authState === 'signedIn' &&
        (path === '/' || path === '/login' || path === '/subscription') &&
        stripeCustomerId !== undefined ? (
          <Redirect to="/dashboard" />
        ) : (
          <AntLayout className={styles.defaultContainer}>
            {showHeader && <Header {...matchProps} {...props} />}
            <Component authState={authState} {...matchProps} {...props} />
            {showFooter && <Footer />}
          </AntLayout>
        )
      }
    />
  );
};

export default AuthLayout;
