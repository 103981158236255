import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';

import {
  unsubscribeMails,
  unsubscribePreference,
  searchOpenSymbol,
  isReferral,
  isPromo,
  monthlyStripeTransaction,
} from './mutations';
import {
  getOpenUploadAttachmentSignedUrl,
  getUploadAttachmentSignedUrl,
  prices,
  searchCounts,
} from './queries';

export const getSymbolSearchCounts = async (payload: Object) =>
  API.graphql({
    variables: { request: payload },
    query: searchCounts,
  }).then(({ data }) => data.searchCounts);
export const getSearchOpenSymbolData = async (symbol) =>
  API.graphql({
    variables: { symbol: symbol },
    query: searchOpenSymbol,
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  }).then(({ data }) => data);

export const getOpenSignedUrl = async (payload) =>
  API.graphql({
    variables: { request: payload },
    query: getOpenUploadAttachmentSignedUrl,
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  }).then(({ data }) => data.getOpenUploadAttachmentSignedUrl);
export const unsubscribeFromMails = async (payload) =>
  API.graphql({
    variables: { request: payload },
    query: unsubscribeMails,
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  }).then(({ data }) => data.unsubscribeMails);
export const unsubscribeMailPreferences = async (payload) =>
  API.graphql({
    variables: { request: payload },
    query: unsubscribePreference,
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  }).then(({ data }) => data.unsubscribePreference);
export const isReferralBoolean = async (payload) =>
  API.graphql({
    variables: { request: payload },
    query: isReferral,
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  }).then(({ data }) => data.isReferral);
export const isPromoBoolean = async (payload) =>
  API.graphql({
    variables: { request: payload },
    query: isPromo,
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  }).then(({ data }) => data.isPromo);
export const makeMonthlyStripeTransaction = async (payload) =>
  API.graphql({
    variables: { request: payload },
    query: monthlyStripeTransaction,
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  }).then(({ data }) => data.monthlyStripeTransaction);

export const getSignedUrl = async (payload) =>
  API.graphql({
    variables: { request: payload },
    query: getUploadAttachmentSignedUrl,
  }).then(({ data }) => data.getUploadAttachmentSignedUrl);

export const getPrices = async (payload) =>
  API.graphql({
    variables: { request: payload },
    query: prices,
  }).then(({ data }) => data.prices);
