import React, { useEffect, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { Table } from '../../components';
import { getEarningsSymbolData } from '../../graphql';
import { useTheme } from '../../ThemeContext';
import { errorHandler, formatColumn, green } from '../../utils';
import { formatDataRow } from '../searchReport/FormatTableColumns';
import styles from './style.module.scss';

const EarningsCalenderBySymbol: React.FC<Props> = ({ history }) => {
  // const { Option } = Select;
  const dispatch = useDispatch();
  const [tableColumns, setTableColumns] = useState();
  const [tableData, setTableData] = useState(null);
  const { theme } = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const [sortDetail, setSortDetail] = useState([
    {
      sortBy: 'eps_perc',
      sortOrder: 'DESC',
    },
  ]);
  const urlSplit = window.location.href.split('=');
  const symbol = urlSplit[urlSplit.length - 1];
  const [symbolSelected, setSymbolSelected] = useState(symbol);

  useEffect(() => {
    setSymbolSelected(symbol);
    setIsLoading(true);
    getEarningsSymbolData(symbolSelected)
      .then((res) => {
        console.log(res);
        dispatch(setTableData(res.earningsData));
        console.log(getGraphData(res.earningsData.data));
        dispatch(
          setTableColumns(arrangeColumns(get(res.earningsData, 'columns'))),
        );
      })
      .catch(errorHandler)
      .finally(() => {
        console.log('tableColumns', tableColumns);
        setIsLoading(false);
      });
  }, [dispatch, tableColumns, sortDetail, symbolSelected]);
  const arrangeColumns = (columns = {}) => {
    const formattedColumns = Object.keys(columns).map((col, index) => ({
      key: col,
      name: col,
      title: col === 'delete' ? 'Delete' : null,
      value: columns[col],
      visible: true,
      index: col === 'symbol' || col === 'symbols' ? 0 : index + 1,
    }));
    return formattedColumns;
  };

  const handleSort = (sortBy: string, sortOrder: string) => {
    setSortDetail([
      {
        sortBy: sortBy,
        sortOrder: sortOrder,
      },
    ]);
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    if (order === 'ascend') {
      handleSort(field, 'ASC');
    } else {
      handleSort(field, 'DESC');
    }
  };
  const getGraphData = (TData, columnData) => {
    const epsEstimate = TData.find((item) => item.type === 'EPS Estimate');
    const epsActual = TData.find((item) => item.type === 'EPS Actual');
    if (epsActual || epsEstimate) {
      const transformedData = Object.keys(epsEstimate)
        .filter((key) => key.startsWith('lastQtr')) // Filter only lastQtr keys
        .map((key) => ({
          name: columnData[key],
          eps_estimate: epsEstimate[key],
          eps_actual: epsActual[key],
        }));
      return transformedData;
    }
    return {};
  };
  // const getUpComingEps = (TData, variable) => {
  //   if (
  //     variable === 'epsGrowth' ||
  //     variable === 'epsBeat' ||
  //     variable === 'revenueGrowth'
  //   ) {
  //     return TData[variable];
  //   }
  //   const epsUpcomingDate = TData.find((item) => item.type === variable);
  //   if (epsUpcomingDate) {
  //     return epsUpcomingDate['lastQtr0'];
  //   }
  //   return new Date();
  // };

  // const onSearchChange = (event) => {
  //   const { value } = event.target;
  //   setSearchedText(value);
  // };

  function onCheckBoxChange() {
    setIsChecked(!isChecked);
  }

  return (
    <>
      <div className={styles.joinTelegramSection}>
        <div>
          {/*<div style={{ width: '150px', margin: 'auto', marginBottom: '10px' }}>*/}
          {/*  <Input*/}
          {/*    placeholder="search symbol..."*/}
          {/*    onChange={onSearchChange}*/}
          {/*    className={styles.VdailySearch}*/}
          {/*    allowClear*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div style={{ marginLeft: 50 }} className={styles.vGoBackSaveBar}>
          <p
            onClick={() => history.push('/search')}
            className={styles.vGoBackBtnBlock}
          >
            <span className={styles.vGoBackBtn}>
              <ArrowLeftOutlined /> Go Back
            </span>
          </p>
          <h3
            style={{
              color: '#2fc058',
              textAlign: 'center',
              paddingBottom: '20px',
              paddingLeft: '300px',
              margin: 'auto',
            }}
          >
            Earnings Report of #{symbolSelected}
          </h3>
          <div style={{ paddingRight: 400 }}>
            <Checkbox checked={isChecked} onChange={onCheckBoxChange}>
              Graph View
            </Checkbox>
          </div>
        </div>
        <div>
          {/*{!isLoading ? (*/}
          {/*  <div*/}
          {/*    style={{*/}
          {/*      display: 'flex',*/}
          {/*      flexDirection: 'row',*/}
          {/*      justifyContent: 'space-evenly',*/}
          {/*      fontWeight: '200',*/}
          {/*      color: 'var(--whitecolor)',*/}
          {/*      width: '600px',*/}
          {/*      height: '130px',*/}
          {/*      border: 'solid 2px #1abd50',*/}
          {/*      borderRadius: '10px',*/}
          {/*      margin: 'auto',*/}
          {/*      marginBottom: 20,*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <div>*/}
          {/*      <div>*/}
          {/*        /!*<span>{`#TSLA`} </span>*!/*/}
          {/*        <br />*/}
          {/*        <span*/}
          {/*          style={{*/}
          {/*            color: 'var(--whitecolor)',*/}
          {/*            fontWeight: '400',*/}
          {/*            paddingLeft: 10,*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          Upcoming EPS Date: (*/}
          {/*          <span>{`${getUpComingEps(*/}
          {/*            get(tableData, 'data', []),*/}
          {/*            'Report Date',*/}
          {/*          )}`}</span>*/}
          {/*          )*/}
          {/*        </span>*/}
          {/*      </div>*/}
          {/*      <div*/}
          {/*        style={{*/}
          {/*          textAlign: 'left',*/}
          {/*          paddingLeft: '10px',*/}
          {/*          margin: 'auto',*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <span*/}
          {/*          style={{*/}
          {/*            textAlign: 'left',*/}
          {/*            margin: 'auto',*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          {`EPS Estimate:`}*/}
          {/*          <span>{`${getUpComingEps(*/}
          {/*            get(tableData, 'data', []),*/}
          {/*            'EPS Estimate',*/}
          {/*          )}`}</span>*/}
          {/*        </span>*/}
          {/*        <br />*/}
          {/*        <span style={{ fontWeight: '400' }}>*/}
          {/*          {`Revenue Estimate:`}*/}
          {/*          <span>*/}
          {/*            {get(tableData, 'revenueEstimate', []) || '-'}*/}
          {/*          </span>{' '}*/}
          {/*        </span>*/}
          {/*        <br />*/}
          {/*        <span*/}
          {/*          style={{*/}
          {/*            fontWeight: '400',*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          Announce:*/}
          {/*          <span>*/}
          {/*            {get(tableData, 'announceType', []) || '-'}*/}
          {/*          </span>{' '}*/}
          {/*        </span>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div>*/}
          {/*      <div>*/}
          {/*        /!*<span>{`#TSLA`} </span>*!/*/}
          {/*        <br />*/}
          {/*        <span*/}
          {/*          style={{*/}
          {/*            color: 'var(--whitecolor)',*/}
          {/*            fontWeight: '400',*/}
          {/*            paddingLeft: 10,*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          EPS Growth Rate Since Last Qtr:*/}
          {/*          <span>{get(tableData, 'epsGrowth', []) || '-'}</span>{' '}*/}
          {/*        </span>*/}
          {/*      </div>*/}
          {/*      <div*/}
          {/*        style={{*/}
          {/*          textAlign: 'left',*/}
          {/*          paddingLeft: '10px',*/}
          {/*          margin: 'auto',*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <span*/}
          {/*          style={{*/}
          {/*            textAlign: 'left',*/}
          {/*            margin: 'auto',*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          {`Revenue Growth Rate Since Last Qtr:`}*/}
          {/*          <span>*/}
          {/*            {get(tableData, 'revenueGrowth', []) || '-'}*/}
          {/*          </span>{' '}*/}
          {/*        </span>*/}
          {/*        <br />*/}
          {/*        <span style={{ fontWeight: '400' }}>*/}
          {/*          {`Avg % Move 1 Week After EPS :`}*/}
          {/*          <span>*/}
          {/*            {get(tableData, 'weekPricePerc', []) + '%' || '-'}*/}
          {/*          </span>{' '}*/}
          {/*        </span>*/}
          {/*        <br />*/}
          {/*        <span*/}
          {/*          style={{*/}
          {/*            fontWeight: '400',*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          Average EPS Beat %:*/}
          {/*          <span>{get(tableData, 'epsBeat', []) || '-'}%</span>{' '}*/}
          {/*        </span>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*) : (*/}
          {/*  <div*/}
          {/*    style={{*/}
          {/*      display: 'flex',*/}
          {/*      margin: 'auto',*/}
          {/*      justifyContent: 'center',*/}
          {/*      alignItems: 'center',*/}
          {/*      marginBottom: 80,*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <div className={styles.loaderStyle}>*/}
          {/*      <BounceLoader color={green} loading={isLoading} size={50} />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
          {!isLoading ? (
            <div
              style={{
                display: 'flex',
                margin: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              hidden={!isChecked}
            >
              <ResponsiveContainer width="50%" height={300}>
                <BarChart
                  data={
                    getGraphData(
                      get(tableData, 'data', []),
                      get(tableData, 'columns'),
                    ) || []
                  }
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  isAnimationActive={true}
                >
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis
                    dataKey="name"
                    tick={{ fontWeight: 'bold', fontSize: 14, fill: '#1abd50' }}
                  />
                  <YAxis
                    tick={{ fontWeight: 'bold', fontSize: 14, fill: '#1abd50' }}
                  />
                  <Tooltip wrapperStyle={{ opacity: 0.8 }} />
                  <Legend />
                  <Bar
                    dataKey="eps_estimate"
                    fill="#8884d8"
                    barSize={20}
                    animationDuration={500}
                    animationEasing="ease-in"
                  />
                  <Bar
                    dataKey="eps_actual"
                    fill="#82ca9d"
                    barSize={20}
                    animationDuration={500}
                    animationEasing="ease-in"
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                margin: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 80,
              }}
            >
              <div className={styles.loaderStyle}>
                <BounceLoader color={green} loading={isLoading} size={50} />
              </div>
            </div>
          )}
          <Table
            style={{ width: '70%', margin: 'auto' }}
            // scroll={{ y: 500 }}
            columns={formatColumn(
              (arrangeColumns(get(tableData, 'columns')) || []).filter(
                (col) => col.visible !== false,
              ),
              true,
              sortDetail,
              history,
              false,
              false,
              'watchList',
              null,
              null,
              theme,
            )}
            dataSource={formatDataRow(get(tableData, 'data', []), theme)}
            loading={isLoading}
            pagination={false}
            size="small"
            bordered={true}
            rowClassName={styles.tableHeader}
            className={styles.TabularData}
            onChange={onTableChange}
            id="allPicksId"
          />
        </div>
      </div>
    </>
  );
};

export default EarningsCalenderBySymbol;
