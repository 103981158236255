import React, { useEffect, useState } from 'react';

import { Form, Row, Button, Col, Select } from 'antd';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import { esSearchCompany } from '../../graphql';
import { green } from '../../utils';
import styles from './style.module.scss';
const { Option } = Select;

interface Props extends RouteComponentProps<any> {
  reportType: string;
  reportSubType: string;
  prevPage: string;
}

const EarningsCalendarSearch: React.FC<Props> = ({
  history,
  reportType,
  reportSubType,
  prevPage,
}) => {
  const [form] = Form.useForm();
  const [symbolData, setSymbolData] = useState([]);
  const [fetching, setFetching] = useState<boolean>(false);
  useEffect(() => {
    if (
      reportSubType === 'EARNINGS_CALENDAR_BY_DATE' &&
      prevPage !== 'searchReport'
    ) {
      history.push({
        pathname: `/earningsCalenderByDate`,
        state: { prevPage: 'search' },
      });
    }
  }, [history, prevPage, reportSubType, reportType]);
  const fetchSymbols = async (value) => {
    setFetching(true);
    const res = await esSearchCompany(value);
    if (res) {
      setSymbolData(res);
    }
    setFetching(false);
  };

  const onFinish = (values) => {
    const { symbol } = values;
    const filters = {
      symbols: symbol,
    };
    history.push({
      pathname:
        `/earningsCalenderBySymbol/` +
        queryString.stringify(filters, {
          skipNull: true,
        }),
      state: { prevPage: 'search' },
    });
  };

  return (
    <Form
      name="price-filter-form"
      onFinish={onFinish}
      labelCol={{ span: 24, padding: 0 }}
      wrapperCol={{ span: 24 }}
      form={form}
      initialValues={{}}
      // onValuesChange={onValuesChange}
      // onFieldsChange={(field) =>
      //   setCurrentField(field[0] ? field[0].name[0] : '')
      // }
    >
      <Row span={24} justify="start" className={styles.buttonSectionBox}>
        {reportSubType === 'EARNINGS_CALENDAR_BY_SYMBOL' && (
          <Col span={24}>
            <Form.Item
              name="symbol"
              label="Search Symbols"
              style={{ marginTop: 20 }}
              className={styles.vFormSearhNew}
              rules={[
                {
                  required: true,
                  message: `Symbol is required`,
                },
              ]}
            >
              <Select
                placeholder="Enter valid symbols"
                mode="multiple"
                notFoundContent={
                  fetching ? (
                    <span className={styles.spinnerStyle}>
                      <BounceLoader color={green} loading={true} size={50} />
                    </span>
                  ) : null
                }
                onSearch={fetchSymbols}
                filterOption={false}
                showSearch
                onSelect={() => setSymbolData([])}
                allowClear={true}
                size="large"
              >
                {symbolData.map((d) => (
                  <Option key={d.symbol} className={styles.optionStyle}>
                    <span className={styles.optionSymbol}>{d.symbol}</span>{' '}
                    {d.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Form.Item colon={false} style={{ margin: 0, marginTop: 20 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};
export default EarningsCalendarSearch;
