import React, { useState, useEffect, createRef, useRef } from 'react';

import { MenuOutlined, CaretDownOutlined } from '@ant-design/icons';
import { /*Row, Col, */ Menu, Dropdown, Tooltip, Layout } from 'antd';
import {
  RouteComponentProps,
  NavLink,
  Link,
  useHistory,
} from 'react-router-dom';

import { Footer } from '../../components';
import {
  // icon1,
  // icon2,
  // icon3,
  // icon4,
  // icon6,
  // play,
  videoPic,
} from '../../images';
import { green, isStudentUrl } from '../../utils';
import Navigation from './Navigation';
import Section from './Section';
import styles from './style2.module.scss';
import './app.css';

interface Props extends RouteComponentProps<any> {
  onStateChange: Function;
  formType: string;
  setFormType: Function;
  user: Object;
}
// const IconFont = createFromIconfontCN({
//   scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
// });
const { Header } = Layout;

const HomePage: React.FC<Props> = () => {
  const history = useHistory();
  const isStudent = isStudentUrl();

  // const vidRef = useRef();
  const vid2Ref = useRef();
  const [activeSection, setActiveSection] = useState('homeVideo');
  const [pageHeight, setPageHeight] = useState<number>();
  // const [videoPlaying, setVideoPlaying] = useState<boolean>(false);
  // const [autoplay, setAutoPlay] = useState<boolean>(false);
  const [testimonialVideoPlaying, setTestimonialVideoPlaying] =
    useState<boolean>(false);
  // const [testimonialVideoMuted, setTestimonialVideoMuted] = useState<boolean>(
  //   true,
  // );

  useEffect(() => {
    const code = window.location.href.includes('code');
    const error = window.location.href.includes('PostConfirmation');
    if (code && !error) {
      history.push('/dashboard');
    }
    setTimeout(() => {
      if (code) {
        window.location.reload();
      }
    }, 2000);
    console.log(testimonialVideoPlaying);
    setPageHeight(window.innerHeight);
    window.addEventListener('resize', (e) => {
      setTimeout(() => {
        setPageHeight(window.innerHeight);
      }, 300);
    });
  }, []);
  useEffect(() => {
    if (activeSection !== 'homeVideo') {
      // vidRef.current.pause();
      setTestimonialVideoPlaying(false);
    }
    // if (activeSection !== 'whyThinkSabio') {
    //   vid2Ref.current.pause();
    // }
  }, [activeSection]);

  // const playVideo = () => {
  //   setVideoPlaying(true);
  //   vid2Ref.current.play();
  // };
  //
  // const playTestimonialVideo = () => {
  //   setTestimonialVideoPlaying(true);
  //   vidRef.current.play();
  // };

  const refs = [
    'homeVideo',
    'homebanner',
    'whyThinkSabio',
    'aboutThinksabio',
    'bestreturn',
    'community',
    'authenticity',
    'captain',
    'value',
    'invest',
  ].reduce((refsObj, i) => {
    refsObj[i] = createRef();
    return refsObj;
  }, {});
  const handleCLick = (name) => {
    refs[name].current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  const menu = (
    <Menu className={styles.mobileMenuNavLink}>
      {/*<Menu.Item key="0">
        <NavLink to="/stories">Stories</NavLink>
      </Menu.Item>*/}
      <Menu.Item key="0">
        <Tooltip
          placement="bottom"
          title={
            <>
              <div
                // className={styles.navLinks}
                style={{
                  padding: '0px 30px 0px 30px',
                  borderRadius: '5px',
                  backgroundColor: 'gainsboro',
                }}
              >
                <NavLink
                  to={{
                    pathname: '/WhoWeAre',
                    state: {
                      name: {
                        properties: {
                          name: 'Overview',
                        },
                      },
                    },
                  }}
                >
                  <div style={{ padding: '10px' }}>
                    <span>Overview</span>
                  </div>
                </NavLink>
              </div>
              <div
                className={styles.navLinks}
                style={{
                  padding: '0px 30px 0px 30px',
                  marginTop: '10px',
                  borderRadius: '5px',
                  backgroundColor: 'gainsboro',
                }}
              >
                <NavLink
                  to={{
                    pathname: '/WhoWeAre',
                    state: {
                      name: {
                        properties: {
                          name: 'Our Features',
                        },
                      },
                    },
                  }}
                >
                  <div style={{ padding: '10px' }}>
                    <span>Our Features</span>
                  </div>
                </NavLink>
              </div>
              <div
                className={styles.navLinks}
                style={{
                  padding: '0px 30px 0px 30px',
                  marginTop: '10px',
                  borderRadius: '5px',
                  backgroundColor: 'gainsboro',
                }}
              >
                <NavLink
                  to={{
                    pathname: '/WhoWeAre',
                    state: {
                      name: {
                        properties: {
                          name: 'Success Stories',
                        },
                      },
                    },
                  }}
                >
                  <div style={{ padding: '10px' }}>
                    <span>Success Stories</span>
                  </div>
                </NavLink>
              </div>
            </>
          }
        >
          <span style={{ fontWeight: 'bold' }}>Who We Are</span>
        </Tooltip>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink to="/login">Login</NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink to="/sign-up">Sign Up</NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink to="/donate">Donate</NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        <Tooltip
          placement="bottom"
          title={
            <>
              <div
                className={styles.navLinks}
                style={{
                  padding: '0px 30px 0px 30px',
                  borderRadius: '5px',
                  backgroundColor: 'gainsboro',
                }}
              >
                <NavLink to="/contact-us">
                  <div style={{ padding: '10px' }}>
                    <span>Contact Us</span>
                  </div>
                </NavLink>
              </div>
              <div
                className={styles.navLinks}
                style={{
                  padding: '0px 30px 0px 30px',
                  marginTop: '10px',
                  borderRadius: '5px',
                  backgroundColor: 'gainsboro',
                }}
              >
                <NavLink to="/privacy-policy">
                  <div style={{ padding: '10px' }}>
                    <span>Privacy Policy</span>
                  </div>
                </NavLink>
              </div>
              <div
                className={styles.navLinks}
                style={{
                  padding: '0px 30px 0px 30px',
                  marginTop: '10px',
                  borderRadius: '5px',
                  backgroundColor: 'gainsboro',
                }}
              >
                <NavLink to="/tnc">
                  <div style={{ padding: '10px' }}>
                    <span>Terms & Conditions</span>
                  </div>
                </NavLink>
              </div>
            </>
          }
        >
          <span style={{ fontWeight: 'bold' }}>Support</span>
        </Tooltip>
      </Menu.Item>
    </Menu>
  );

  const S0 = () => (
    <>
      <div className={styles.bannerVideo} style={{ width: '100%' }}>
        <div className={styles.firstLine}>
          Giving you the tools to build a bright financial future.
        </div>
        <div style={{ marginTop: 50 }}>
          <p
            className={styles.firstParagraph}
            // style={{
            //   width: '80%',
            //   textAlign: 'left',
            //   fontSize: '25px',
            //   margin: 'auto',
            // }}
          >
            Have you ever been confused by the stock market and wondered how to
            find the best investment for your financial future ?
          </p>
          <p className={styles.firstParagraph}>
            ThinkSabio can help. We offer simple and impactful information from
            the most successful institutional investors like Berkshire Hathaway
            and JP Morgan.
          </p>
          <p className={styles.firstParagraph}>
            We make these insights clear and actionable so people of every
            income and education level can invest wisely and model their
            success.
          </p>
          <div style={{ marginTop: 60, marginLeft: 100 }}>
            <CaretDownOutlined />
            <span style={{ marginLeft: 20 }}>Scroll Down to Learn More</span>
          </div>
        </div>
      </div>
    </>
  );
  // {
  //   /*const S1 = () => (
  //   <div className={styles.homeBanner}>
  //     <img alt="home_page_img" className={styles.bannerImage} src={banner} />

  //     <div className={styles.homeBannerText}>
  //       <h2>
  //         Seed is powerless until it's planted,
  //         <br />
  //         Money is worthless until it's invested.
  //       </h2>
  //     </div>
  //     {/* <img alt="home_page_img" className={styles.muteImage} src={mute} />
  //   </div>
  // );*/
  // }

  const S2 = () => (
    <div className={styles.whyThinkSabio}>
      <div className={styles.whyThinkSabioContent}>
        <div className={styles.whyThinkSabioText}>
          <h2>Why ThinkSabio</h2>
          <p>
            <h3 style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>
              Our core features:
            </h3>
            <ul>
              <li>
                Access to stock pricing, trends, and forecast value on over
                10,000 stocks
              </li>
              <li>
                Explore comprehensive data on over 10,000 institutions' stock
                holdings, updated on a quarterly and daily basis, sourced
                directly from the U.S. Securities and Exchange Commission (SEC).
              </li>
              <li>
                Gain insights from the trading activities of 3,000 insiders,
                with detailed information on their buying and selling
                transactions. Uncover valuable stock market intelligence right
                at your fingertips.
              </li>
              <li>
                40 methods and strategies for picking the best-performing
                stocks, backed by technical analysis
              </li>
              <li>And much more!</li>
            </ul>
            <h4 style={{ marginTop: '0.5rem' }}>
              With our valuable, easily accessible information, ThinkSabio can
              simplify the stock market and give you the keys to a bright
              financial future!
            </h4>
          </p>
          {/*{isStudent ? (*/}
          {/*  <Link to="/sign-up">*/}
          {/*    <button style={{ color: green, width: '50%' }}>*/}
          {/*      Join Us Now For Free*/}
          {/*    </button>*/}
          {/*  </Link>*/}
          {/*) : (*/}
          {/*  <Link to="/free-trial">*/}
          {/*    <button>Try For Free</button>*/}
          {/*  </Link>*/}
          {/*)}*/}
          <div style={{ marginTop: 80, color: 'black' }}>
            <CaretDownOutlined />
            <span style={{ marginLeft: 20 }}>Scroll Down to Learn More</span>
          </div>
        </div>
        <div className={styles.whyThinkSabioVideo}>
          <video
            width="100%"
            height="100%"
            src="https://thinksabio-asset.s3.amazonaws.com/ThinkSabio_Video_Animation.mp4"
            controls={true}
            disablePictureInPicture
            controlsList="nodownload"
            playsInline
            id="myVideo"
            poster={videoPic}
            ref={vid2Ref}
          >
            <source
              src="https://thinksabio-asset.s3.amazonaws.com/ThinkSabio_Video_Animation.mp4"
              type="video/mp4"
            />
          </video>

          {/* {!videoPlaying && (
            <div className={styles.playCircle}>
              <img
                className={styles.playimage}
                src={play}
                onClick={() => playVideo()}
                alt="play"
              />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );

  // const S3 = () => (
  //   <div className={styles.aboutThinksabio}>
  //     <h2>Inherent Features of ThinkSabio</h2>
  //     <p>
  //       <span>Join ThinkSabio </span>
  //       now for getting unlimited access 24/7/365 days to
  //     </p>
  //     <div className={styles.aboutThinksabioContent}>
  //       <Row>
  //         <Col xs={24} sm={12} lg={9}>
  //           <div className={styles.aboutIconText}>
  //             <img alt="home_page_img" src={icon1} />
  //             <p>7,100+</p>
  //           </div>
  //           <h6>Stock's prices and their trends</h6>
  //         </Col>
  //         <Col xs={24} sm={12} lg={9}>
  //           <div className={styles.aboutIconText}>
  //             <img alt="home_page_img" src={icon2} />
  //             <p>7,100+</p>
  //           </div>
  //           <h6>
  //             We provide individual statistical report for each of the stocks
  //             and forecast value for all the stocks
  //           </h6>
  //         </Col>
  //         <Col xs={24} sm={12} lg={6}>
  //           <div className={styles.aboutIconText}>
  //             <img alt="home_page_img" src={icon3} />
  //             <p>40</p>
  //           </div>
  //           <h6>
  //             Technical analysis methods and strategies for picking best
  //             performing stocks
  //           </h6>
  //         </Col>
  //         <Col xs={24} sm={12} lg={9}>
  //           <div className={styles.aboutIconText}>
  //             <img alt="home_page_img" src={icon4} />
  //             <p>10,000+</p>
  //           </div>
  //           <h6>
  //             Institution's quarterly, daily stock holdings from Security
  //             Exchange Commission(SEC)
  //           </h6>
  //         </Col>
  //         {/*<Col xs={24} sm={12} lg={9}>
  //         {/* <Col xs={24} sm={12} lg={9}>
  //           <div className={styles.aboutIconText}>
  //             <img alt="home_page_img" src={icon5} />
  //             <p>40+</p>
  //           </div>
  //           <h6>
  //             Technical analysis methods and strategies for picking best winning
  //             stocks
  //           </h6>
  //         </Col>
  //         </Col> */}
  //         <Col xs={24} sm={12} lg={6}>
  //           <div className={styles.aboutIconText}>
  //             <img alt="home_page_img" src={icon6} />
  //             <p>3,000+</p>
  //           </div>
  //           <h6>Insider's buying/selling information from SEC</h6>
  //         </Col>
  //       </Row>
  //     </div>
  //   </div>
  // );

  const S4 = () => (
    <div className={styles.bestReturn}>
      <div className={styles.bestReturnContentText}>
        <div className={styles.bestReturnTextimage}>
          <img
            alt="home_page_img"
            src="https://thinksabio-asset.s3.amazonaws.com/solid.png"
          />
        </div>
        <div className={styles.bestReturnTextText}>
          <h2>Effective and Simple</h2>
          <p>
            We help make stock picking easy to understand for people who don’t
            have time to figure it out, and our advice has helped investors
            generate high returns.
          </p>
          <h2>Focused</h2>
          <p>
            Our innovative data-mining techniques bring you stock information
            quickly and intelligently.
          </p>
          <h2>Value</h2>
          <p>
            Our stock data and insights give our investors confidence to invest
            well and thrive through the stock market.
          </p>
          <h4>
            Try now to simplify the stock market and invest in your future!
          </h4>
          {/*{isStudent ? (*/}
          {/*  <Link to="/sign-up">*/}
          {/*    <button style={{ color: green, width: '50%' }}>*/}
          {/*      Join Us Now For Free*/}
          {/*    </button>*/}
          {/*  </Link>*/}
          {/*) : (*/}
          {/*  <Link to="/free-trial">*/}
          {/*    <button>Try For Free</button>*/}
          {/*  </Link>*/}
          {/*)}*/}
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
  // const S5 = () => (
  //   <div className={styles.community}>
  //     <div className={styles.communityContent}>
  //       <div className={styles.communityContentText}>
  //         <h2>Community Involvement</h2>
  //         <p>Tested- Proven-Successful with 10,000+ ThinkSabio community.</p>
  //         {isStudent ? (
  //           <Link to="/sign-up">
  //             <button style={{ color: green, width: '50%' }}>
  //               Join Us Now For Free
  //             </button>
  //           </Link>
  //         ) : (
  //           <Link to="/free-trial">
  //             <button>Try For Free</button>
  //           </Link>
  //         )}
  //       </div>
  //       <div className={styles.communityContentImage}>
  //         <img
  //           alt="home_page_img"
  //           src="https://thinksabio-asset.s3.amazonaws.com/community.png"
  //         />
  //       </div>
  //     </div>
  //   </div>
  // );

  // const S6 = () => (
  //   <div className={styles.bestReturn}>
  //     <div className={styles.bestReturnContentText}>
  //       <div className={styles.returnTextimage}>
  //         <img
  //           alt="home_page_img"
  //           src="https://thinksabio-asset.s3.amazonaws.com/authentiicity.png"
  //         />
  //       </div>
  //       <div className={styles.bestReturnTextText}>
  //         <h2>We Are Focused</h2>
  //         <p>
  //           Profound data-mining techniques to bring stock's information quickly
  //           and intelligently to you.
  //         </p>
  //         {isStudent ? (
  //           <Link to="/sign-up">
  //             <button style={{ color: green, width: '50%' }}>
  //               Join Us Now For Free
  //             </button>
  //           </Link>
  //         ) : (
  //           <Link to="/free-trial">
  //             <button>Try For Free</button>
  //           </Link>
  //         )}
  //       </div>
  //     </div>
  //   </div>
  // );

  // const S7 = () => (
  //   <div className={styles.captain}>
  //     <div className={styles.captainImage}>
  //       <img
  //         alt="home_page_img"
  //         src="https://thinksabio-asset.s3.amazonaws.com/captain.png"
  //       />
  //     </div>
  //     <div className={styles.captainContent}>
  //       <div className={styles.captainContentText}>
  //         <h2>Be a Captain</h2>
  //         <p>
  //           Our goal is helping our investor's accrue wealth by making them the
  //           captains of their own ship.
  //         </p>
  //         {isStudent ? (
  //           <Link to="/sign-up">
  //             <button style={{ color: green, width: '60%' }}>
  //               Join Us Now For Free
  //             </button>
  //           </Link>
  //         ) : (
  //           <Link to="/free-trial">
  //             <button>Try For Free</button>
  //           </Link>
  //         )}
  //       </div>
  //       <div className={styles.captainRight}>
  //         <p>
  //           Our goal is helping our investor's accrue wealth by making them the
  //           captains of their own ship.
  //         </p>
  //       </div>
  //     </div>
  //   </div>
  // );

  // const S8 = () => (
  //   <div className={styles.captain}>
  //     <div className={styles.valueContent}>
  //       <div className={styles.captainContentText}>
  //         <h2>Value</h2>
  //         <p>
  //           We provide mountain of confirmation, confidence, and courage to our
  //           investor's with our stock's data and research.
  //         </p>
  //         {isStudent ? (
  //           <Link to="/sign-up">
  //             <button style={{ color: green, width: '60%' }}>
  //               Join Us Now For Free
  //             </button>
  //           </Link>
  //         ) : (
  //           <Link to="/free-trial">
  //             <button>Try For Free</button>
  //           </Link>
  //         )}
  //       </div>
  //       <div className={styles.captainRight}>
  //         <p>
  //           We provide mountain of confirmation, confidence, and courage to our
  //           investor's with our stock's data and research.
  //         </p>
  //       </div>
  //     </div>
  //     <div className={styles.captainImage}>
  //       <img
  //         alt="home_page_img"
  //         src="https://thinksabio-asset.s3.amazonaws.com/value.jpg"
  //       />
  //     </div>
  //   </div>
  // );

  // const S9 = () => (
  //   <div className={styles.investBox}>
  //     <div className={styles.investBoxContent}>
  //       <div>
  //         <img
  //           src="https://thinksabio-asset.s3.amazonaws.com/TSLOGO.png"
  //           alt="logo"
  //         />
  //       </div>
  //       {/*<h2>Stocks Simplified</h2>*/}
  //       {isStudent ? (
  //         <Link to="/sign-up">
  //           <button style={{ color: green, width: '60%' }}>
  //             Join Us Now For Free
  //           </button>
  //         </Link>
  //       ) : (
  //         <Link to="/free-trial">
  //           <button>Try For Free</button>
  //         </Link>
  //       )}
  //       <div className={styles.homeFooterBox}>
  //         <div className={styles.homeFooterContent}>
  //           <p>ThinkSabio © 2021</p>
  //           <ul>
  //             <li>
  //               <NavLink to="/tnc">Terms & Conditions</NavLink>
  //             </li>
  //             <li>
  //               <NavLink to="/privacy-policy">Privacy Policy</NavLink>
  //             </li>
  //             {/* <li>
  //             <NavLink to="">Privacy</NavLink>
  //           </li>
  //           <li>
  //             <NavLink to="">License</NavLink>
  //           </li>
  //           <li>
  //             <NavLink to="">Stories</NavLink>
  //           </li> */}
  //             <li>
  //               <NavLink to="/contact-us">Contact Us</NavLink>
  //             </li>
  //             {/* <li>
  //             <Link to="#">
  //               <TwitterOutlined />
  //             </Link>
  //             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  //             <Link to="#">
  //               <IconFont type="icon-facebook" />
  //             </Link>
  //           </li> */}
  //           </ul>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div className={styles.homePageContainer}>
      <div className={styles.headerSubHeader}>
        <Header
          className={styles.headerStaticMainBar}
          // style={{
          //   boxShadow:
          //     scrollY < 90
          //       ? '0px 0px 0px rgba(0, 0, 0, 0)'
          //       : '0px 4px 4px rgba(0, 0, 0, 0.05)',
          // }}
        >
          <div className={styles.logoHeader}>
            <div style={{ marginRight: 'calc(15vh - 8px)' }}>
              <img
                src="https://thinksabio-asset.s3.amazonaws.com/TSLOGO.png"
                alt="logo"
              />
            </div>
            <div className={styles.homeHeader}>
              <div className={styles.headerContentBox}>
                <div className={styles.headerlink}>
                  <Dropdown
                    overlay={menu}
                    trigger={['click']}
                    className={styles.mobileMenuHome}
                    placement="bottomCenter"
                  >
                    <p
                      onClick={(e) => e.preventDefault()}
                      className="ant-dropdown-link"
                    >
                      <MenuOutlined />
                    </p>
                  </Dropdown>
                  <ul className={styles.menuLinkHome}>
                    <li>
                      <Tooltip
                        placement="bottom"
                        title={
                          <>
                            <div
                              className={styles.navLinks}
                              style={{
                                padding: '0px 30px 0px 30px',
                                borderRadius: '5px',
                                backgroundColor: 'gainsboro',
                              }}
                            >
                              <NavLink
                                to={{
                                  pathname: '/WhoWeAre',
                                  state: {
                                    name: {
                                      properties: {
                                        name: 'Overview',
                                      },
                                    },
                                  },
                                }}
                              >
                                <div
                                  style={{ padding: '10px', width: '100px' }}
                                >
                                  <span>Overview</span>
                                </div>
                              </NavLink>
                            </div>
                            <div
                              className={styles.navLinks}
                              style={{
                                padding: '0px 30px 0px 30px',
                                marginTop: '10px',
                                borderRadius: '5px',
                                backgroundColor: 'gainsboro',
                              }}
                            >
                              <div style={{ padding: '10px', width: '100px' }}>
                                <a
                                  href="https://blog.thinksabio.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Blog
                                </a>
                              </div>
                            </div>
                            <div
                              className={styles.navLinks}
                              style={{
                                padding: '0px 30px 0px 30px',
                                marginTop: '10px',
                                borderRadius: '5px',
                                backgroundColor: 'gainsboro',
                              }}
                            >
                              <NavLink
                                to={{
                                  pathname: '/WhoWeAre',
                                  state: {
                                    name: {
                                      properties: {
                                        name: 'Our Features',
                                      },
                                    },
                                  },
                                }}
                              >
                                <div style={{ padding: '10px' }}>
                                  <span>Our Features</span>
                                </div>
                              </NavLink>
                            </div>
                            <div
                              className={styles.navLinks}
                              style={{
                                padding: '0px 30px 0px 30px',
                                marginTop: '10px',
                                borderRadius: '5px',
                                backgroundColor: 'gainsboro',
                              }}
                            >
                              <NavLink
                                to={{
                                  pathname: '/WhoWeAre',
                                  state: {
                                    name: {
                                      properties: {
                                        name: 'Success Stories',
                                      },
                                    },
                                  },
                                }}
                              >
                                <div style={{ padding: '10px' }}>
                                  <span>Success Stories</span>
                                </div>
                              </NavLink>
                            </div>
                          </>
                        }
                      >
                        <button
                          style={{
                            width: 150,
                            color: 'white !important',
                            backgroundColor: 'black !important',
                          }}
                        >
                          Who We Are
                        </button>
                      </Tooltip>
                    </li>
                    {isStudent ? (
                      ''
                    ) : (
                      <>
                        <li>
                          <NavLink
                            to={{
                              pathname: '/donate',
                            }}
                          >
                            <button>Donate</button>
                          </NavLink>
                        </li>
                        <li>
                          <Link to="/free-trial">
                            <button style={{ color: green }}>Sign Up</button>
                          </Link>
                        </li>
                      </>
                    )}
                    {isStudent ? (
                      <li style={{ marginRight: '40px' }}>
                        <Link to="/sign-up">
                          <button
                            style={{
                              color: green,
                              width: '121%',
                              marginRight: '100px',
                              height: '50px',
                            }}
                          >
                            Join Us Now For Free
                          </button>
                        </Link>
                      </li>
                    ) : (
                      ''
                      // <li>
                      //   <Link to="/pricing">
                      //     <button>Pricing</button>
                      //   </Link>
                      // </li>
                    )}
                    <li>
                      <Link to="/login">
                        <button>Login</button>
                      </Link>
                    </li>
                    <li>
                      <Tooltip
                        placement="bottom"
                        title={
                          <>
                            <div
                              className={styles.navLinks}
                              style={{
                                padding: '0px 30px 0px 30px',
                                borderRadius: '5px',
                                backgroundColor: 'gainsboro',
                              }}
                            >
                              <NavLink to="/contact-us">
                                <div style={{ padding: '10px' }}>
                                  <span>Contact Us</span>
                                </div>
                              </NavLink>
                            </div>
                            <div
                              className={styles.navLinks}
                              style={{
                                padding: '0px 30px 0px 30px',
                                marginTop: '10px',
                                borderRadius: '5px',
                                backgroundColor: 'gainsboro',
                              }}
                            >
                              <NavLink to="/privacy-policy">
                                <div style={{ padding: '10px' }}>
                                  <span>Privacy Policy</span>
                                </div>
                              </NavLink>
                            </div>
                            <div
                              className={styles.navLinks}
                              style={{
                                padding: '0px 30px 0px 30px',
                                marginTop: '10px',
                                borderRadius: '5px',
                                backgroundColor: 'gainsboro',
                              }}
                            >
                              <NavLink to="/tnc">
                                <div style={{ padding: '10px' }}>
                                  <span>Terms & Conditions</span>
                                </div>
                              </NavLink>
                            </div>
                          </>
                        }
                      >
                        <button
                          style={{
                            width: 150,
                            color: 'white !important',
                            backgroundColor: 'black !important',
                          }}
                        >
                          Support
                        </button>
                      </Tooltip>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/*<Menu*/}
          {/*  theme="light"*/}
          {/*  mode="horizontal"*/}
          {/*  className={styles.headerMenuBar}*/}
          {/*  defaultSelectedKeys={['0']}*/}
          {/*></Menu>*/}
        </Header>
      </div>
      <Section
        activeCharacter={activeSection}
        setActiveCharacter={setActiveSection}
        pageHeight={pageHeight}
        refs={refs}
        id="homeVideo"
        name="homeVideo"
      >
        <S0 />
      </Section>
      {/*<Section
        activeCharacter={activeSection}
        setActiveCharacter={setActiveSection}
        pageHeight={pageHeight}
        refs={refs}
        id="homebanner"
        name="homebanner"
      >
        <S1 />
      </Section>*/}

      <Section
        activeCharacter={activeSection}
        setActiveCharacter={setActiveSection}
        pageHeight={pageHeight}
        refs={refs}
        id="whyThinkSabio"
        name="whyThinkSabio"
      >
        <S2 />
      </Section>
      {/*<Section*/}
      {/*  activeCharacter={activeSection}*/}
      {/*  setActiveCharacter={setActiveSection}*/}
      {/*  pageHeight={pageHeight}*/}
      {/*  refs={refs}*/}
      {/*  id="aboutThinksabio"*/}
      {/*  name="aboutThinksabio"*/}
      {/*>*/}
      {/*  <S3 />*/}
      {/*</Section>*/}
      <Section
        activeCharacter={activeSection}
        setActiveCharacter={setActiveSection}
        pageHeight={pageHeight}
        refs={refs}
        id="bestreturn"
        name="bestreturn"
      >
        <S4 />
      </Section>
      {/*<Section*/}
      {/*  activeCharacter={activeSection}*/}
      {/*  setActiveCharacter={setActiveSection}*/}
      {/*  pageHeight={pageHeight}*/}
      {/*  refs={refs}*/}
      {/*  id="community"*/}
      {/*  name="community"*/}
      {/*>*/}
      {/*  <S5 />*/}
      {/*</Section>*/}
      {/*<Section*/}
      {/*  activeCharacter={activeSection}*/}
      {/*  setActiveCharacter={setActiveSection}*/}
      {/*  pageHeight={pageHeight}*/}
      {/*  refs={refs}*/}
      {/*  id="authenticity"*/}
      {/*  name="authenticity"*/}
      {/*>*/}
      {/*  <S6 />*/}
      {/*</Section>*/}
      {/*<Section*/}
      {/*  activeCharacter={activeSection}*/}
      {/*  setActiveCharacter={setActiveSection}*/}
      {/*  pageHeight={pageHeight}*/}
      {/*  refs={refs}*/}
      {/*  id="captain"*/}
      {/*  name="captain"*/}
      {/*>*/}
      {/*  <S7 />*/}
      {/*</Section>*/}
      {/*<Section*/}
      {/*  activeCharacter={activeSection}*/}
      {/*  setActiveCharacter={setActiveSection}*/}
      {/*  pageHeight={pageHeight}*/}
      {/*  refs={refs}*/}
      {/*  id="value"*/}
      {/*  name="value"*/}
      {/*>*/}
      {/*  <S8 />*/}
      {/*</Section>*/}
      {/*<Section*/}
      {/*  activeCharacter={activeSection}*/}
      {/*  setActiveCharacter={setActiveSection}*/}
      {/*  pageHeight={pageHeight}*/}
      {/*  refs={refs}*/}
      {/*  id="invest"*/}
      {/*  name="invest"*/}
      {/*>*/}
      {/*  <S9 />*/}
      {/*</Section>*/}
      <Navigation
        items={[
          'homeVideo',
          'whyThinkSabio',
          // 'aboutThinksabio',
          'bestreturn',
          // 'community',
          // 'authenticity',
          // 'captain',
          // 'value',
          // 'invest',
        ]}
        activeCharacter={activeSection}
        handleCLick={handleCLick}
      />
    </div>
  );
};

export default HomePage;
