import React, { useState, useEffect } from 'react';

import { Table, Row, Empty, Collapse, Button, List, Tooltip } from 'antd';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import { getSavedSearchName } from '../../graphql';
// import { ultimateImg } from '../../images';
import { useTheme } from '../../ThemeContext';
import {
  green,
  formatTotalRow,
  formatTableData,
  currencyFormat,
  errorHandler,
} from '../../utils';
import {
  getGraphQlQueryByType,
  getSearchSymbolTablesData,
} from '../searchReport/SearchReport.helper';
import styles from '../searchReport/style.module.scss';

const { Panel } = Collapse;
interface Props extends RouteComponentProps<any> {
  authState: string;
}
const SearchSymbolOpenPage: React.FC<Props> = ({
  history,
  match,
  authState,
}) => {
  const { theme } = useTheme();
  const [searchData, setSearchData] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openKeys, setOpenKeys] = useState(['3']);
  const [sortDetail, setSortDetail] = useState([]);
  const urlSplit = window.location.href.split('/');
  const symbol = urlSplit[urlSplit.length - 1];
  const reportType = 'SYMBOL';
  const reportSubType = 'INDIVIDUAL_STATISTICAL_OPEN_REPORT';
  useEffect(() => {
    console.log('Open Report', authState);
    if (authState === 'signedIn') {
      setTimeout(() => {
        history.push({
          pathname: `/search/report/subtype=individual_statistical_report&symbols=${
            urlSplit[urlSplit.length - 1]
          }&type=symbol`,
          state: { prevPage: 'reports' },
        });
      }, 1000);
    }
    const { query, queryData } = getGraphQlQueryByType(
      reportType,
      reportSubType,
      { symbols: symbol },
      null,
      null,
      null,
      null,
      null,
      null,
      sortDetail,
      null,
      null,
      null,
      null,
      null,
      null,
    );
    if (queryData && queryData.filter && queryData.filters.type) {
      delete queryData.filters.type;
    }
    const promises = [
      query,
      getSavedSearchName(
        JSON.stringify({
          type: reportType,
          subType: reportSubType,
          request: queryData,
        }),
      ),
    ];
    setIsLoading(true);
    Promise.all(promises)
      .then((res) => {
        setIsLoading(true);
        setSearchData(res[0]);
      })
      .catch((err) => {
        errorHandler(err);
        setSearchData({});
      })
      .finally(() => {
        setIsLoading(false);
      });
    console.log('ISR report');
  }, [authState]);

  let tablesData = getSearchSymbolTablesData(
    searchData,
    symbol,
    sortDetail,
    theme,
    history,
    null,
    null,
  );
  const onPanelChange = (keys) => {
    setOpenKeys(keys);
  };
  const openAllPanel = () => {
    let keys = new Array(tablesData.length)
      .fill(0)
      .map((_, i) => (i + 1).toString());

    setOpenKeys(keys);
  };
  const tableLoading = {
    spinning: isLoading,
    indicator: (
      <span className={styles.spinnerStyle}>
        <BounceLoader color={green} loading={true} size={50} />
      </span>
    ),
  };

  const onTableChange = (pagination, filters, sorter, extra, key) => {
    const { field, order } = sorter;
    setSortDetail([
      {
        key: key,
        sortBy: field,
        sortOrder: order === 'ascend' ? 'ASC' : 'DESC',
      },
    ]);
  };
  const redirectToSignUp = () => {
    history.push({
      pathname: `/sign-up`,

      state: { prevPage: '/reports/isr/TSLA' },
    });
  };
  const redirectToImage = (image) => {
    window.open(image, '_blank');
    window.location.reload();
  };
  const getSortedData = (key, data) => {
    const sortObj = sortDetail.find((d) => d.key === key);
    if (sortObj) {
      const newData = data.sort((a, b) => {
        let keyA =
          sortObj.sortBy === 'dateReported' ||
          sortObj.sortBy === 'transactionDate' ||
          sortObj.sortBy === 'reportDate'
            ? new Date(a[sortObj.sortBy])
            : a[sortObj.sortBy];
        let keyB =
          sortObj.sortBy === 'dateReported' ||
          sortObj.sortBy === 'transactionDate' ||
          sortObj.sortBy === 'reportDate'
            ? new Date(b[sortObj.sortBy])
            : b[sortObj.sortBy];
        // Compare the 2 dates
        if (sortObj.sortOrder === 'ASC') {
          if (!isNaN(+keyA)) {
            return keyA - keyB;
          } else {
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          }
        } else {
          if (!isNaN(+keyA)) {
            return keyB - keyA;
          } else {
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
          }
        }
      });

      // const newData = orderBy(
      //   data,
      //   [sortObj.sortBy],
      //   [sortObj.sortOrder.toLowerCase()],
      // );
      console.log(newData);
      return newData;
    }
    return data;
  };

  return (
    <div style={{ marginTop: '85px' }} className={styles.isrReport}>
      <div>
        <div className={styles.vSavedBlockedNew}>
          {!isLoading && (
            <>
              <p style={{ fontSize: 20, textTransform: 'uppercase' }}>
                {' '}
                {` Individual Statistical Report for (${symbol})`}
              </p>
            </>
          )}
          {!isLoading && (
            <Row justify="end" span={24} style={{ marginBottom: 20 }}>
              {openKeys.length !== tablesData.length ? (
                <Button onClick={openAllPanel}>Open All</Button>
              ) : (
                <Button onClick={() => setOpenKeys([])}>Close All</Button>
              )}
            </Row>
          )}

          {/*<div className={styles.titleContainer}>*/}
          {/*  /!* <h2 style={{ marinTop: 0 }}>{title}</h2> *!/*/}
          {/*</div>*/}
        </div>
      </div>
      <Row style={{ marginTop: '-20px' }} span={24} id="isr">
        {isLoading && (
          <div className={styles.loaderStyle}>
            <BounceLoader color={green} loading={isLoading} size={150} />
          </div>
        )}

        {tablesData.length > 0 && (
          <Collapse
            defaultActiveKey={['1']}
            expandIconPosition="left"
            style={{ width: '100%' }}
            activeKey={openKeys}
            onChange={onPanelChange}
          >
            {tablesData.map(
              (field, i) =>
                field.data.length > 0 && (
                  <Panel
                    style={{ color: 'red' }}
                    header={field.title.toUpperCase()}
                    key={i + 1}
                    id="vPanelChange"
                    // extra={
                    //   i === 0 ? (
                    //     <Button onClick={openAllPanel}>Open All</Button>
                    //   ) : (
                    //     ''
                    //   )
                    // }
                  >
                    {field.type === 'table' && (
                      <div className={styles.searchPaneTable}>
                        <Table
                          scroll={{ x: true }}
                          loading={tableLoading}
                          columns={field.columns}
                          dataSource={formatTableData(
                            getSortedData(field.name, field.data),
                          )}
                          showSorterTooltip={false}
                          pagination={false}
                          size="small"
                          bordered={true}
                          rowClassName={styles.tableHeader}
                          className={styles.TabularData}
                          onChange={(a, b, c, d) =>
                            onTableChange(a, b, c, d, field.name)
                          }
                          id="allPicksId"
                          summary={
                            !field.summary
                              ? () => {}
                              : () => {
                                  return (
                                    <Table.Summary.Row>
                                      {formatTotalRow(field.summary[0]).map(
                                        (r, i) => (
                                          <Table.Summary.Cell index={i} key={i}>
                                            {r}
                                          </Table.Summary.Cell>
                                        ),
                                      )}
                                    </Table.Summary.Row>
                                  );
                                }
                          }
                        />
                        {field.name === 'ownersInfo' && (
                          <p
                            style={{
                              fontSize: 12,
                            }}
                          >
                            *Data Source{' '}
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={field.dataSource}
                              style={{
                                textDecoration: 'underline',
                                fontSize: 12,
                                color: green,
                              }}
                            >
                              SEC
                            </a>
                          </p>
                        )}
                        {field.name === 'mutualFundQuarterlyShares' && (
                          <p
                            style={{
                              textDecoration: 'underline',
                              fontSize: 12,
                              color: green,
                              marginTop: 10,
                              cursor: 'pointer',
                              textAlign: 'center',
                            }}
                            onClick={() => {
                              const filters = {
                                subtype:
                                  'SYMBOL_SUMMARY_MUTUAL_FUNDS'.toLowerCase(),
                                type: 'MUTUAL_FUNDS'.toLowerCase(),
                                symbols: symbol,
                              };
                              if (history) {
                                history.push(
                                  `/search/report/` +
                                    queryString.stringify(filters),
                                );
                              }
                            }}
                          >
                            View All
                          </p>
                        )}
                        {field.name === 'insiderTransactions' && (
                          <p
                            style={{
                              textDecoration: 'underline',
                              fontSize: 12,
                              color: green,
                              marginTop: 10,
                              cursor: 'pointer',
                              textAlign: 'center',
                            }}
                            onClick={() => {
                              const filters = {
                                type: 'SEC_INSIDERS'.toLowerCase(),
                                subtype:
                                  'INSIDER_DETAIL_SUMMARY_REPORT'.toLowerCase(),
                                symbols: symbol,
                              };
                              if (history) {
                                history.push(
                                  `/search/report/` +
                                    queryString.stringify(filters),
                                );
                              }
                            }}
                          >
                            View All
                          </p>
                        )}
                      </div>
                    )}
                    {field.type === 'table' &&
                      field.title.includes('RECENT TRANSACTIONS') && (
                        <p
                          style={{
                            color: 'var(--whitecolor)',
                            marginLeft: '710px',
                            marginTop: '5px',
                          }}
                        >
                          {' '}
                          Total :{' '}
                          {currencyFormat(
                            field.total || 0,
                            false,
                            false,
                            2,
                            false,
                            true,
                          )}
                        </p>
                      )}
                    {/*{field.type === 'table' &&*/}
                    {/*  field.title.includes('Comparison by Quarterly Price') && (*/}
                    {/*    <p*/}
                    {/*      style={{*/}
                    {/*        color: 'var(--whitecolor)',*/}
                    {/*        marginLeft: '100px',*/}
                    {/*        marginTop: '5px',*/}
                    {/*        fontSize: '10px',*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      Formula For Approx Invested Value : (Shares Diff * Qtr*/}
                    {/*      Avg Price)*/}
                    {/*    </p>*/}
                    {/*  )}*/}

                    {field.type === 'news' && (
                      <List
                        size="small"
                        // header={}
                        bordered
                        dataSource={field.data}
                        renderItem={(item: {}) => (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                <>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={item.url}
                                  >
                                    <p className={styles.newsTitle}>
                                      {item.title}
                                    </p>
                                  </a>

                                  <p className={styles.news_time}>
                                    {`${item.source} - ${moment(item.timestamp)
                                      .tz('America/New_York')
                                      .format('hh:mm A')},

                                ${moment(item.timestamp)
                                  .tz('America/New_York')
                                  .format('DD MMM YYYY')}`}
                                  </p>
                                </>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    )}
                  </Panel>
                ),
            )}
          </Collapse>
        )}
        {tablesData.length === 3 && !isLoading && (
          <div
            style={{
              margin: 'auto',
              fontSize: '30px',
            }}
          >
            <p>
              <a
                style={{ fontSize: '35px' }}
                onClick={redirectToSignUp}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here
              </a>{' '}
              to Register and get full access to this report and many{' '}
              <Tooltip
                placement="bottom"
                title={
                  <>
                    <p>
                      Below are a few of our unprecedented reports. Click on the
                      report name to get a glimpse of the report in a new tab.
                    </p>
                    <div
                      // className={styles.navLinks}
                      style={{
                        padding: '0px 30px 0px 30px',
                        borderRadius: '5px',
                        backgroundColor: 'gainsboro',
                        marginBottom: '10px',
                      }}
                    >
                      <div style={{ padding: '10px', color: 'black' }}>
                        <span
                          onClick={() =>
                            redirectToImage(
                              '/static/media/diamondsScreenshot.4f6f1385.png',
                            )
                          }
                        >
                          ThinkSabio Diamonds
                        </span>
                      </div>
                    </div>
                    <div
                      // className={styles.navLinks}
                      style={{
                        padding: '0px 30px 0px 30px',
                        borderRadius: '5px',
                        backgroundColor: 'gainsboro',
                        marginBottom: '10px',
                      }}
                    >
                      <div
                        onClick={() =>
                          redirectToImage(
                            '/static/media/ultimateReport.5b266ae8.png',
                          )
                        }
                        style={{ padding: '10px', color: 'black' }}
                      >
                        <span>Ultimate Reports</span>
                      </div>
                    </div>
                    <div
                      // className={styles.navLinks}
                      style={{
                        padding: '0px 30px 0px 30px',
                        borderRadius: '5px',
                        backgroundColor: 'gainsboro',
                        borderColor: 'red !important',
                      }}
                    >
                      <div style={{ padding: '10px', color: 'black' }}>
                        {/*<Tooltip*/}
                        {/*  overlayStyle={{*/}
                        {/*    width: '100%',*/}
                        {/*    height: '100%',*/}
                        {/*  }}*/}
                        {/*  placement="bottom"*/}
                        {/*  title={*/}
                        {/*    <div*/}
                        {/*      style={{*/}
                        {/*        display: 'flex',*/}
                        {/*        justifyContent: 'center',*/}
                        {/*      }}*/}
                        {/*    >*/}
                        {/*      <img*/}
                        {/*        style={{*/}
                        {/*          height: '100%',*/}
                        {/*          width: '100%',*/}
                        {/*          marginTop: '20px',*/}
                        {/*          marginBottom: '50px',*/}
                        {/*        }}*/}
                        {/*        alt="dss"*/}
                        {/*        src={ultimateImg}*/}
                        {/*      />*/}
                        {/*    </div>*/}
                        {/*  }*/}
                        {/*>*/}
                        <span
                          onClick={() =>
                            redirectToImage(
                              '/static/media/markMinervini.048c00ec.png',
                            )
                          }
                        >
                          Mark Minervini Strategy Report
                        </span>
                        {/*</Tooltip>*/}
                      </div>
                    </div>
                  </>
                }
              >
                <span style={{ textDecoration: 'underline' }}>
                  unprecedented Reports
                </span>
              </Tooltip>
            </p>
          </div>
        )}
      </Row>
      <Row span={24} justify="center">
        {tablesData.length === 0 && !isLoading && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Row>
    </div>
  );
};

export default SearchSymbolOpenPage;
